<template>
    <div>
        <div class="mt-4">
            <div v-if="users.length" class="d-flex flex-wrap">
                <div
                    v-for="(item, index) in users"
                    :key="item.id"
                    class="col-12 col-md-6 col-lg-4 p-2"
                >
                    <div
                        class="d-flex justify-content-between p-2 h-min-24 border border-primary rounded"
                    >
                        <div class="d-flex flex-column justify-content-around">
                            <span class="text-primary font-weight-bold">
                                {{
                                    item.firstName
                                        ? $options.filters.cut(
                                              `${item.firstName} ${item.lastName}`,
                                              30
                                          )
                                        : item.email
                                }}
                            </span>
                            <span>
                                {{ item.role.name }}
                            </span>
                        </div>

                        <div
                            v-if="enabled && item.email !== user.email"
                            class="d-flex"
                        >
                            <button
                                class="btn len-3 d-flex align-items-center justify-content-center"
                                @click="editItem(index)"
                            >
                                🖊
                            </button>
                            <button
                                class="btn btn-danger len-3 d-flex align-items-center justify-content-center"
                                @click="deleteItem(index)"
                            >
                                🗑️
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="col-10 mx-auto py-3">
                <span class="h4">
                    Nuestro sistema no ha encotrado usuarios registrados 0️⃣.
                </span>
            </div>
            <div
                v-if="enabled"
                class="my-4 col-12 d-flex justify-content-center"
            >
                <vs-tooltip border>
                    <button
                        class="w-100 btn btn-outline-info rounded h-24 d-flex justify-content-center align-items-center"
                        @click="openNewItem"
                    >
                        <h2>&nbsp;➕&nbsp;</h2>
                    </button>
                    <template #tooltip> Invitar Colaborador</template>
                </vs-tooltip>
            </div>
        </div>
        <div>
            <vs-dialog
                v-model="isOpenModalSelectItem"
                scroll
                overflow-hidden
                auto-width
            >
                <template #header>
                    <h3>Asociar Usuario A Alamacén</h3>
                </template>
                <div class="col-10 mx-auto py-2">
                    <span v-if="associatedAt">
                        Este usuario fue asociado al comercio el
                        {{ associatedAt | date }}. Selecciona el rol que quieres
                        asignarle al usuario.
                    </span>
                    <span v-else>
                        Ingresa el correo electrónico y selecciona el rol que
                        quieres asignarle al usuario.
                    </span>
                    <div class="my-4">
                        <span> Email </span>
                        <vs-input
                            id="email"
                            v-model="email"
                            :disabled="isEditingItem"
                            autocomplete="off"
                            border
                            shadow
                            type="text"
                            name="email"
                        >
                            <template #message-danger>
                                <div v-show="introEmail && !isValidEmail">
                                    Ingrese correo valido
                                </div>
                            </template>
                        </vs-input>
                    </div>

                    <div v-if="showDuplicate" class="mx-3 mt-2">
                        <span class="h3 text-danger mt-4">
                            Ya tienes un usuario con el correo
                            {{ email }}
                        </span>
                    </div>

                    <card-selector v-model="role" :base="options" />

                    <div class="d-flex justify-content-center mt-4">
                        <vs-button
                            :disabled="isInvalidCurrentItem"
                            class="mt-3 mb-5 col-9 mx-auto"
                            border
                            gradient
                            @click="pushOrUpdateItem"
                        >
                            <span class="h4">
                                {{
                                    isEditingItem
                                        ? "Actualizar usuario ✔️"
                                        : "Agregar usuario ✔️"
                                }}</span
                            >
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { emailRegex } from "@/common/lib/regex";

import CardSelector from "@/components/utils/CardSelector.vue";

export default {
    name: "UsersRolesManage",
    components: { CardSelector },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        },
        enabled: {
            default: () => false,
            type: Boolean,
            required: false
        },
        defaultFilters: {
            default: () => ({}),
            type: Object,
            required: false
        }
    },
    data: () => ({
        role: "",
        email: "",
        associatedAt: "",
        introEmail: false,
        isLoading: false,
        isEditingItem: false,
        isOpenModalSelectItem: false,
        options: [],
        users: [
            {
                id: "",
                uid: "",
                email: "",
                firstName: "",
                identificationNumber: "",
                identificationType: "",
                lastName: "",
                phone: "",
                associatedAt: "",
                role: {
                    slug: "",
                    name: ""
                }
            }
        ]
    }),
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("control", ["backgroundColor"]),
        isValidEmail() {
            return emailRegex.test(this.email);
        },
        showDuplicate() {
            if (this.isEditingItem) return false;
            return this.itemsCurrent.some((item) => item.email === this.email);
        },
        isInvalidCurrentItem() {
            return this.role === "" || !this.isValidEmail;
        }
    },
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        ...mapActions("roles", ["listAllRoles"]),
        async listRolesComponent() {
            this.isLoading = true;
            try {
                const items = await this.listAllRoles({
                    ...this.defaultFilters
                });
                this.options = items.map((item) => ({
                    id: item.slug,
                    name: item.name,
                    description: item.description,
                    icon: "💠"
                }));
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        setInput(value) {
            this.users = [...value];
        },
        editItem(fromIndex) {
            this.isEditingItem = true;
            let arrAux = [...this.itemsCurrent];
            const itemFound = arrAux[fromIndex];
            this.isOpenModalSelectItem = true;
            this.email = itemFound.email;
            this.associatedAt = itemFound?.associatedAt;
            if (this.options.length) {
                return;
            }
            this.options = [
                {
                    id: itemFound.role.slug,
                    name: itemFound.role.name,
                    description: itemFound.role.name,
                    icon: "💠"
                }
            ];
            this.role = itemFound.role.slug;
            this.listRolesComponent();
        },
        openNewItem() {
            this.isEditingItem = false;
            this.isOpenModalSelectItem = true;
            this.email = "";
            this.associatedAt = "";
            this.listRolesComponent();
        },
        pushOrUpdateItem() {
            const itemsAux = [...this.itemsCurrent];
            const role = this.options.find((item) => item.id === this.role);
            if (this.isEditingItem) {
                const itemAux = itemsAux.findIndex(
                    (item) => item.email === this.email
                );

                itemsAux[itemAux] = {
                    ...itemsAux[itemAux],
                    role: {
                        slug: role.id,
                        name: role.name,
                        description: role.description
                    }
                };
                this.isEditingItem = false;
            } else {
                itemsAux.push({
                    email: this.email,
                    role: {
                        name: role.name,
                        slug: role.id,
                        description: role.description
                    }
                });
            }
            this.$emit("change", itemsAux);
            this.isOpenModalSelectItem = false;
        },
        deleteItem(fromIndex) {
            let itemsResult = [...this.itemsCurrent];
            itemsResult.splice(fromIndex, 1);
            this.$emit("change", itemsResult);
        },
        onBlurEmail() {
            this.introEmail = true;
            this.email = this.email.toLowerCase().trim();
        }
    }
};
</script>
