:
<template>
    <div>
        <div class="row">
            <table v-if="itemsCurrent.length" class="table" :class="tableDark">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Precio</th>
                        <th v-if="enabled" scope="col">
                            <div class="d-flex justify-content-center">
                                <span>🧤</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in itemsCurrent" :key="index">
                        <th scope="col-1">{{ index }}</th>
                        <td class="col-5">
                            <span>
                                {{ item.name }}
                            </span>
                        </td>
                        <td class="col-4">
                            <span>
                                {{ item.priceOffer | money }}
                            </span>
                        </td>

                        <td v-if="enabled" class="col-2">
                            <div class="d-flex justify-content-center">
                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    :disabled="index === 0"
                                    @click="moveOrder(index, index - 1)"
                                >
                                    ⬆
                                </button>

                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    :disabled="index >= itemsCurrent.length - 1"
                                    @click="moveOrder(index, index + 1)"
                                >
                                    ⬇
                                </button>
                                <button
                                    class="btn btn-danger len-3 d-flex align-items-center justify-content-center"
                                    @click="deleteService(index)"
                                >
                                    🗑️
                                </button>
                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    @click="editService(index)"
                                >
                                    🖊
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="col-10 mx-auto py-3">
                <span class="h4">
                    Nuestro sistema no ha encotrado servicios registrados 0️⃣.
                </span>
            </div>
        </div>

        <div v-if="enabled" class="my-4 col-12 d-flex justify-content-center">
            <vs-tooltip border>
                <button
                    class="w-100 btn btn-outline-info rounded h-24 d-flex justify-content-center align-items-center"
                    @click="openNewService"
                >
                    <h2>&nbsp;➕&nbsp;</h2>
                </button>
                <template #tooltip> Agregar servicios</template>
            </vs-tooltip>
        </div>

        <div>
            <vs-dialog
                v-model="isOpenModalSelectService"
                scroll
                overflow-hidden
                auto-width
            >
                <template #header> </template>
                <div class="con-content border rounded border-info py-2 px-4">
                    <span class="h3">
                        {{
                            isEditingService
                                ? "Edita servicio"
                                : "Nuevo servicio"
                        }}
                    </span>
                    <div class="d-flex flex-wrap">
                        <div class="col-6 mt-4">
                            <span> Nombre </span>
                            <vs-input
                                id="name_"
                                v-model="filterTmp.name"
                                border
                                shadow
                                autocomplete="off"
                                type="text"
                                name="name"
                                placeholder="Nombre del servicio"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <span> Precio </span>
                            <vs-input
                                id="start_"
                                v-model="priceOffer"
                                border
                                shadow
                                autocomplete="off"
                                step="10000"
                                type="text"
                                name="start"
                                @input="formatPriceOffer"
                            />
                        </div>
                    </div>

                    <div v-if="showDuplicate" class="mx-3 mt-2">
                        <span class="h3 text-danger mt-4">
                            Ya tienes un servicio con el nombre
                            {{ filterTmp.name }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <vs-button
                            :disabled="isInvalidCurrentService"
                            class="mt-3 mb-5 col-9 mx-auto"
                            border
                            gradient
                            @click="pushNewService"
                        >
                            <span class="h4"> Guardar ✔️ </span>
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ServicesInMerchantManage",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [
                {
                    name: "alt",
                    priceOffer: 5000
                }
            ],
            required: true
        },
        enabled: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        showDuplicate: false,
        priceOffer: "$10.000",
        isEditingService: false,
        isOpenModalSelectService: false,
        filterTmp: {
            name: "",
            priceOffer: 20000
        }
    }),
    computed: {
        ...mapGetters("control", ["tableDark"]),
        isInvalidCurrentService() {
            return (
                this.filterTmp.name === "" ||
                this.filterTmp.priceOffer < 0 ||
                this.showDuplicate
            );
        }
    },
    watch: {},
    methods: {
        moveOrder(fromIndex, toIndex) {
            let arrAux = [...this.itemsCurrent];
            const aux = arrAux[fromIndex];
            arrAux.splice(fromIndex, 1);
            arrAux.splice(toIndex, 0, aux);
            this.$emit("change", arrAux);
        },
        editService(fromIndex) {
            this.isEditingService = true;
            let arrAux = [...this.itemsCurrent];
            this.filterTmp = arrAux[fromIndex];
            this.isOpenModalSelectService = true;
        },
        deleteService(fromIndex) {
            let filtersResult = [...this.itemsCurrent];
            filtersResult.splice(fromIndex, 1);
            this.$emit("change", filtersResult);
        },
        openNewService() {
            this.isEditingService = false;
            this.filterTmp = {
                name: "",
                priceOffer: 10000
            };
            this.isOpenModalSelectService = true;
        },
        pushNewService() {
            const filtersAux = [...this.itemsCurrent];
            if (this.isEditingService) {
                const itemAux = filtersAux.findIndex(
                    (item) => item.key === this.filterTmp.key
                );
                filtersAux[itemAux] = this.filterTmp;
                this.isEditingService = false;
            } else {
                filtersAux.push(this.filterTmp);
            }
            this.$emit("change", filtersAux);
            this.isOpenModalSelectService = false;
        },
        formatPriceOffer() {
            this.filterTmp.priceOffer = parseInt(
                this.priceOffer.replace(/[^0-9]+/g, "")
            );
            this.priceOffer = this.$options.filters.currency(
                this.filterTmp.priceOffer
            );
        },
        formatName() {
            if (this.filterTmp.name === "Nombre del servicio") {
                this.filterTmp.name = "";
            }
        }
    }
};
</script>
