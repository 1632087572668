<template>
    <div class="d-flex flex-wrap">
        <div
            v-for="(item, index) in base"
            v-show="!item.scope || $ability.hasScope(item.scope)"
            :key="index"
            class="col-12 col-md-6 d-flex justify-content-center"
        >
            <button
                class="btn btn-primary my-2 text-left h-min-32 col-11 d-flex flex-column justify-content-center"
                :class="
                    itemCurrent === item.id ? 'border-3 border-highlight' : ''
                "
                @click="selectItem(item.id)"
            >
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <span class="h3">{{ item.name }}</span>
                    <span>{{ item.icon || "🔍" }}</span>
                </div>
                <p>{{ item.description }}</p>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardsSelector",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => "",
            type: String,
            require: true
        },
        base: {
            default: () => [],
            type: Array,
            require: true
        }
    },
    emits: ["change"],
    data: () => ({}),
    watch: {},
    methods: {
        selectItem(id) {
            this.$emit("change", id);
        }
    }
};
</script>
