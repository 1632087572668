<template>
    <div class="d-flex flex-wrap mt-2">
        <div class="col-12 col-md-6 mt-5">
            <input-money-component
                v-model="itemComponent.cash"
                border
                :enabled="enabled"
                label="Dinero en caja"
                class="opacity-false"
                @blur="onInput"
            />
        </div>
        <div class="col-12 col-md-6 mt-5">
            <input-money-component
                v-model="itemComponent.nequi"
                border
                :enabled="enabled"
                label="Nequi"
                class="opacity-false"
                @blur="onInput"
            />
        </div>
        <div class="col-12 col-md-6 mt-5">
            <input-money-component
                v-model="itemComponent.daviplata"
                border
                :enabled="enabled"
                label="Daviplata"
                class="opacity-false"
                @blur="onInput"
            />
        </div>
        <div class="col-12 col-md-6 mt-5">
            <input-money-component
                v-model="itemComponent.terminal"
                border
                :enabled="enabled"
                label="Datáfono"
                class="opacity-false"
                @blur="onInput"
            />
        </div>
    </div>
</template>

<script>
import { EMPTY_MONEY_CONFIG } from "@/common/lib/money";

import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "MoneyConfigManage",
    components: {
        InputMoneyComponent
    },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: Object,
            default: () => EMPTY_MONEY_CONFIG,
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        itemComponent: EMPTY_MONEY_CONFIG
    }),
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onInput() {
            this.$emit("change", this.itemComponent);
        },
        setInput(value) {
            this.itemComponent = { ...value };
        }
    }
};
</script>
