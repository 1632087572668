<template>
    <div>
        <div class="row mb-1">
            <div
                class="col-12 d-flex justify-content-between align-items-center"
            >
                <h4>Posición geográfica</h4>
            </div>
            <div class="col-12">
                <p>
                    Recuerda debes indicarnos tu ubicación haciendo click en el
                    mapa.
                </p>
            </div>
        </div>
        <div v-show="showMessageCoors" class="row col-12">
            <span class="text-danger">Debes ubicar tu dirección</span>
        </div>
        <div class="d-flex flex-wrap">
            <div
                class="card col-xl-10 col-lg-10 col-11 mx-auto map-template border rounded p-0"
                :class="showMessageCoors ? 'border-danger' : 'border-success'"
            >
                <l-map ref="mainMap" @ready="loadMap()"></l-map>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LocationSelector",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: Object,
            default: () => ({ latitude: 4.63082, longitude: -74.15035 }),
            required: true
        }
    },
    emits: ["change"],
    data: () => ({
        showMessageCoors: false,
        lat: 4.63082,
        lon: -74.15035,
        introCoors: false
    }),
    computed: {
        validLon() {
            return Number(this.lon) > -180 && Number(this.lon) < 180;
        },
        validLat() {
            return Number(this.lon) > -180 && Number(this.lon) < 180;
        }
    },
    watch: {
        itemCurrent(value) {
            if ("latitude" in value) {
                this.lat = value.latitude;
                this.lon = value.longitude;
                this.$map.removeAllMarkers();
                this.$map.addMarker("1", [this.lat, this.lon], "<p>🏫 Dir</p>");
            }
        }
    },
    methods: {
        loadMap() {
            this.$map.init(this.$refs.mainMap.mapObject, this.onClickMap);
            if ("latitude" in this.itemCurrent) {
                this.lat = this.itemCurrent.latitude;
                this.lon = this.itemCurrent.longitude;
                this.$map.addMarker("1", [this.lat, this.lon], "<p>🏫 Dir</p>");
            }
            this.$map.setView([this.lat, this.lon]);
            this.enableMap = false;
        },
        onClickMap(event) {
            this.introCoors = true;
            this.showMessageCoors = false;
            this.$emit("change", {
                latitude: Number(Number(event.latlng.lat).toFixed(7)),
                longitude: Number(Number(event.latlng.lng).toFixed(7))
            });
        }
    }
};
</script>
