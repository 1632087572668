<template>
    <div>
        <div class="position-relative">
            <label class="left-13px"> Coordenadas </label>
        </div>
        <div class="d-flex justify-content-around align-items-center">
            <div v-if="itemTemp" class="d-flex flex-column mx-auto col-10">
                <small> Lat : {{ itemTemp.latitude }} </small>
                <small> Lon : {{ itemTemp.longitude }} </small>
            </div>
            <span v-else class="ml-4">Sin ubicación</span>
            <vs-button
                v-if="enabled"
                icon
                circle
                @click="openLocationModal = !openLocationModal"
            >
                🗺️
            </vs-button>
        </div>

        <vs-dialog v-model="openLocationModal" blur not-padding>
            <div class="container my-4">
                <map-selector v-model="itemTemp" @change="onChange" />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import MapSelector from "@/components/utils/MapSelector.vue";

export default {
    name: "CoordinatesSelector",
    components: { MapSelector },
    model: { event: "change", prop: "itemCurrent" },
    props: {
        itemCurrent: {
            default: () => ({
                latitude: 4.63082,
                longitude: -74.15035
            }),
            type: Object,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        itemTemp: {
            latitude: 4.63082,
            longitude: -74.15035
        },
        openLocationModal: false
    }),
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onChange(value) {
            this.setInput(value);
        },
        setInput(value) {
            this.itemTemp = value;
        }
    }
};
</script>
